import styled from "styled-components"
import { SecondaryTitle } from "../../global/common/text"

const Lead = styled(SecondaryTitle)`
  --spacing-bottom: 5rem;

  font-weight: 400;
`

export default Lead
