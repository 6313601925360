import styled from "styled-components"

const Avatar = styled.img`
  width: var(--avatar-size, 5rem);
  height: var(--avatar-size, 5rem);
  flex-shrink: 0;
  object-fit: cover;

  margin-right: 2rem;

  &,
  & > [data-placeholder-image] {
    border-radius: 50%;
  }
`

export default Avatar
