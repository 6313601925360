import React from "react"
import styled, { css } from "styled-components"

import { minD, maxD } from "../../assets/styles/helpers/devices"

const ArticleContainer = styled.article`
  ${({ fullWidth }) =>
    fullWidth
      ? css`
          padding: 0 17.5%;

          @media ${maxD.laptop} {
            padding: 0 10%;
          }

          @media ${maxD.tabletL} {
            padding: 0;
          }
        `
      : css`
          max-width: 100%;

          @media ${minD.tabletL} {
            width: calc(100% - 320px);
            padding-left: calc(var(--section-spacing) * 0.7);
          }
        `}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:not(:first-child) {
      margin-top: 1.5rem;
    }
  }

  ul,
  ol {
    --bottom-padding: 0.65rem;
  }

  img {
    max-width: calc(100vw - 50px);
    height: auto;
  }

  article {
    max-width: calc(100vw - 100px);
  }

  p {
    font-weight: 400;
  }
`

const Lead = styled.p`
  font-size: 1.2rem;
  margin-bottom: 4rem;
`

export const Article = ({ leadHeader, leadText, content, fullWidth }) => (
  <ArticleContainer className="content" {...{ fullWidth }}>
    {leadHeader && <h2>{leadHeader}</h2>}
    {leadText && <Lead dangerouslySetInnerHTML={{ __html: leadText }} />}
    {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
  </ArticleContainer>
)
