import React from "react"
import styled from "styled-components"

import Arrow from "../../../assets/images/svg/right-arrow.svg"

import Avatar from "../../global/atoms/Avatar"

import { Box } from "../../case-study/common/boxes"
import { fadedGreenBox } from "../../global/common/ornaments"
import { UnderlinedLink } from "../../global/common/links"
import { isLinkAnAnchor } from "../../../assets/styles/helpers/common"

import { minD, maxD } from "../../../assets/styles/helpers/devices"

const Container = styled(Box)`
  position: relative;
  width: 100%;
  margin-bottom: 3rem;

  background-color: #fff;

  @media ${minD.tabletL} {
    ${fadedGreenBox}
  }

  @media ${maxD.tablet} {
    --box-padding-horizontal: 2.2rem;
  }

  &::after {
    top: 15px;
    right: -20px;
  }
`

const CustomAvatar = styled(Avatar)`
  margin-bottom: 2.2rem;
`

const Description = styled.p`
  margin-bottom: 2.2rem;
  font-weight: 300;

  @media ${minD.tablet} {
    font-size: 0.92rem;
  }
`

const Author = ({ data }) => {
  if (!data) return null

  const teamMember = data.acfTeamMember

  const description = teamMember?.authorDescription

  const link = {
    title: teamMember?.authorLink?.title,
    url: teamMember?.authorLink?.url,
    target: teamMember?.authorLink?.target,
  }

  const image = {
    url: teamMember?.singleUserImage?.localFile.childImageSharp.gatsbyImageData,
    alt: teamMember?.singleUserImage?.altText,
  }

  return (
    <Container as="div">
      <CustomAvatar image={image.url} alt={image.alt} />
      <Description dangerouslySetInnerHTML={{ __html: description }} />
      {link.title && (
        <UnderlinedLink
          to={link.url}
          target={link.target}
          anchor={isLinkAnAnchor(link.url)}
        >
          {link.title}
          <Arrow />
        </UnderlinedLink>
      )}
    </Container>
  )
}

export default Author
