import React from "react"
import { graphql } from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"
import styled from "styled-components"

import Layout from "../assets/styles/layout"
import CallToAction from "../components/global/CallToAction"
import Footer from "../components/global/Footer"
import Lead from "../components/blog/atoms/Lead"
import Author from "../components/blog/single-post/Author"
import Share from "../components/blog/single-post/Share"
import DefaultHeader from "../components/global/header/DefaultHeader"

import {
  FooterContainer,
  LineWrapperWithSpaces,
} from "../components/global/common/containers"
import { Article } from "../components/global/Article"
import {
  GreenBoxContainer,
  DotsContainer,
} from "../components/global/common/ornaments"

import { minD, maxD } from "../assets/styles/helpers/devices"

export const query = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      title
      content
      date(formatString: "DD.MM.YYYYr.")
      categories {
        nodes {
          name
          id
          slug
        }
      }
      acfPost {
        leadShort
        leadLong
        author {
          ... on WpTeamMember {
            acfTeamMember {
              authorDescription
              authorLink {
                target
                title
                url
              }
              singleUserImage {
                altText
                atttachement_pl {
                  altTextPl
                }
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
      featuredImage {
        node {
          altText
          atttachement_pl {
            altTextPl
          }
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`

const Intro = styled(GreenBoxContainer).attrs({
  as: Lead,
})`
  position: relative;
  font-size: 1.5rem;

  @media ${minD.laptopL} {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  @media ${maxD.tablet} {
    font-size: 1.25rem;
  }

  &::after {
    right: 35px;
    top: calc(var(--section-spacing) * -1 - 35px);
    z-index: 9;

    @media ${maxD.mobileL} {
      content: none;
    }
  }
`

const PostContent = styled(DotsContainer)`
  display: flex;
  align-items: flex-start;

  @media ${maxD.tabletL} {
    flex-direction: column-reverse;
  }

  &::after {
    max-width: 100%;
    width: 850px;
    height: 700px;
  }
`

const Sidebar = styled.aside`
  --box-padding-vertical: 2.7rem;
  --box-padding-horizontal: 2.7rem;

  width: 320px;
  flex-shrink: 0;

  @media ${maxD.tabletL} {
    width: 100%;
    margin-top: 2rem;
  }
`

const Post = ({ pageContext, data: { wpPost } }) => {
  const { leadLong, author } = wpPost.acfPost
  const { content, seo, title, categories, featuredImage, date } = wpPost

  const image = featuredImage;

  return (
    <Layout>
      {seo && process.env.GATSBY_SITE_ENV === "production" && (
        <Seo post={wpPost} />
      )}
      <DefaultHeader {...{ title, image, date, categories }} pageContext={pageContext} />
      <LineWrapperWithSpaces as="main">
        <Intro dangerouslySetInnerHTML={{ __html: leadLong }} />
        <PostContent as="div">
          <Sidebar>
            <Author data={author} />
            <Share {...{ title }} />
          </Sidebar>
          <Article {...{ content }} />
        </PostContent>
      </LineWrapperWithSpaces>
      <FooterContainer>
        <CallToAction />
        <Footer />
      </FooterContainer>
    </Layout>
  )
}

export default Post
